import React from "react"
import styled from "styled-components"

const WrapperDiv = styled.div`
    max-width: 1110px;
    margin: 0 auto 30px;
    display: flex;
    flex-wrap: wrap;
    padding: 0 20px;

    @media only screen and (max-width: 1024px) {
        margin-top: 40px;
        margin-bottom: 0;
    }

    h2 {
        width: 100%;
    }
`;

const Flex = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 100px;
    @media only screen and (max-width: 1024px) {
        margin-bottom: 90px;
    }
`;

const DetailFlex = styled.div`
    display: flex;
    justify-content: center;

    @media only screen and (max-width: 1140px) {
        padding: 0 20px;
    }

`;

const DetailText = styled.div`
    padding: 47px 0 80px;
    max-width: 1110px;
    display: flex;
    flex-wrap: wrap;
`


const Content = styled.div `
    width: 50%;
    margin-bottom: 160px;
   
    &.content.left {
        padding-left: 100px
    }

    &.content.right {
        padding-right: 100px;
    }

    @media only screen and (max-width: 1140px) {
        width: 100%;
        padding: 0;
        margin-bottom: 100px;

        &.content {
            margin-bottom: 0;
        }

        &.content.left {
            padding-left: 0;
        }

        &.content.right {
            padding-right: 0;
        }
    }


    &.text {
        padding-right: 10px;
        padding-left: 10px;
        @media only screen and (max-width: 1140px) {
            width: 100%;
            padding-left: 0;
            padding-right: 0;
            margin-bottom: 0;
        }
    }
   
    &.full-width {
        width:100%;
        padding-bottom: 0;
    }

    .row {
        justify-content: space-between;

        &.last {
            padding-bottom: 0;
        }

        @media only screen and (max-width: 1140px) {
            padding-bottom: 0;
            .col-6 {
                padding-bottom: 60px;
            }
        }

    }

    .column {
       max-width: 45%;
    }
    
    @media only screen and (max-width: 1140px) {
        padding: 0;

        .column {
            max-width: unset;
         }

         img {
            max-width: 500px;
         }
    }
    
    @media only screen and (max-width: 768px) {
        img {
            max-width: 100%;
         }
    } 

    .img-container {
        display: flex;
        justify-content: center;
        width: 100%
    }
`;

const Details = () => (
    <>
        <DetailFlex>
            <DetailText>
                <p className="intro">Ladd Partners is proud to have been selected as an early Certinia Customer Success Cloud partner, recognized for our proven expertise in driving successful implementations and adoption. Leveraging our deep knowledge of Salesforce, Certinia modules, and critical business processes, we bring best practices to your entire business—from Lead to Renewal.</p>
            </DetailText>
        </DetailFlex>

        <WrapperDiv>
        <Flex>
            <h2 className="margin-bottom-30">Customer Success Cloud Key Benefits</h2>

            <Content className="full-width">
                <div className="row">
                    <div className="column col-6">
                        <h4 className="margin-bottom-0">Drillable Customer Health</h4>
                        <p className="margin-bottom-40">Comprehensive health insights tailored to your team, from usage trends to evaluations and beyond. </p>
                    </div>
                    <div className="column col-6">
                        <h4 className="margin-bottom-0">Outcome Driven Success Plans</h4>
                        <p className="margin-bottom-40">Build trust and make value visible to your customers by aligning on customer goals with tailored success plans for consistent, proactive customer engagement.</p>
                    </div>
                </div>

                <div className="row">
                    <div className="column col-6">
                        <h4 className="margin-bottom-0">Specialized Playbooks </h4>
                        <p className="margin-bottom-40">We help you generate and tailor dynamic playbooks that eliminate guesswork and assumed knowledge, ensuring your system helps drive best practices.</p>
                    </div>
                    <div className="column col-6">
                        <h4 className="margin-bottom-0">Collaboration & Visibility Across Teams</h4>
                        <p className="margin-bottom-40">Create cross-team harmony with one, unified platform at every step of your customer's journey and anticipate your customer's needs.</p>
                    </div>
                </div>

                <div className="row">
                    <div className="column col-6">
                        <h4 className="margin-bottom-0">Enable Automation & Scalability </h4>
                        <p className="margin-bottom-40">Repeatable best practices for all types of accounts, low-touch and high-touch, allowing your team to scale confidently without sacrificing quality.</p>
                    </div>
                    <div className="column col-6">
                        <h4 className="margin-bottom-0">Actionable Insights & Analytics</h4>
                        <p className="margin-bottom-40">Dashboards with real-time reports, trigger playbooks based on real-time customer activities, and drill down into key data with the power of Salesforce Analytics. </p>
                    </div>
                </div>

            </Content>

                  </Flex>
        </WrapperDiv>
    </>
)

export default Details

